<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Medication</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.medicationForm.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Medication</span>
              </v-tooltip>
            </v-card-title>

            <v-data-table
              :headers="medicationHeaders"
              :items="dog.medications"
              no-data-text="No medications Records"
              :items-per-page="-1"
            >
              <template v-slot:item.age="{ item }">
                {{ dog.dob ? ageWeek(dog.dob, item.date) : null }}
              </template>

              <template v-slot:item.date="{ item }">
                {{ formatDateTime(item.date, false) }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.medicationForm.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit New Medication</span>
                </v-tooltip>

                <v-btn
                  bottom
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  @click="openDelete(item, 'Medication')"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Notes</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.noteForm.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Note</span>
              </v-tooltip>
            </v-card-title>

            <v-data-table
              :headers="noteHeaders"
              :items="dog.health_notes"
              no-data-text="No Notes"
              :items-per-page="-1"
            >
              <template v-slot:item.age="{ item }">
                {{ dog.dob ? ageWeek(dog.dob, item.date) : null }}
              </template>

              <template v-slot:item.date="{ item }">
                {{ formatDateTime(item.date, false) }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.noteForm.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-btn
                  bottom
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  @click="openDelete(item, 'HealthNote')"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Records</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.recordForm.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Record</span>
              </v-tooltip>
            </v-card-title>

            <v-data-table
              :headers="recordHeaders"
              :items="dog.health_records"
              no-data-text="No records"
              :items-per-page="-1"
            >
              <template v-slot:item.date="{ item }">
                {{ formatDateTime(item.date, false) }}
              </template>

              <template v-slot:item.document="{ item }">
                <v-btn
                  dark
                  small
                  class="mt-2"
                  depressed
                  :href="item.asset_urls.document"
                  target="_blank"
                >
                  <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                </v-btn>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.recordForm.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-btn
                  bottom
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  @click="openDelete(item, 'healthRecord')"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>

          <v-card>
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Defects</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.defectsForm.openForm(dog.defects)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Defects</span>
              </v-tooltip>
            </v-card-title>

            <v-simple-table
              class="mb-5 table"
              divider="true"
              v-if="dog.defects"
            >
              <tbody>
                <tr>
                  <th>Base Narrow Canines</th>
                  <td>{{ dog.defects.base_narrow_canines ? "Yes" : "No" }}</td>
                </tr>

                <tr>
                  <th>Umbilical Hernia</th>
                  <td>{{ dog.defects.umbilical_hernia ? "Yes" : "No" }}</td>
                </tr>

                <tr>
                  <th>Heart Murmur</th>
                  <td>{{ dog.defects.heart_murmur ? "Yes" : "No" }}</td>
                </tr>

                <tr>
                  <th>Underbite</th>
                  <td>{{ dog.defects.underbite ? "Yes" : "No" }}</td>
                </tr>

                <tr>
                  <th>Overbite</th>
                  <td>{{ dog.defects.overbite ? "Yes" : "No" }}</td>
                </tr>

                <tr>
                  <th>Open Face</th>
                  <td>{{ dog.defects.open_face ? "Yes" : "No" }}</td>
                </tr>

                <tr>
                  <th>Comments</th>
                  <td>{{ dog.defects.comments }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <MedicationForm ref="medicationForm" />
      <NoteForm ref="noteForm" />
      <RecordForm ref="recordForm" />
      <DefectsForm ref="defectsForm" />
      <v-dialog
        scrollable
        v-model="deleteBreed.dialog"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline"
            >Archive {{ deleteBreed.item }}</v-card-title
          >
          <v-card-text>Are you sure you wish to archive?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteBreed.loading"
              @click="saveDelete(deleteBreed.item)"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import MedicationForm from "../components/health/MedicationForm";
import NoteForm from "../components/health/NoteForm";
import RecordForm from "../components/health/RecordForm";
import DefectsForm from "../components/health/DefectsForm";

import moment from "moment";

export default {
  components: {
    MedicationForm,
    NoteForm,
    RecordForm,
    DefectsForm,
  },

  data() {
    return {
      deleteBreed: {
        dialog: false,
        item: null,
        model: {},
        loading: false,
      },

      recordHeaders: [
        { text: "Date", value: "date" },
        { text: "Doc. Upload", value: "document" },
        { text: "Comments", value: "comments" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      medicationHeaders: [
        { text: "Date", value: "date" },
        { text: "Age (Weeks)", value: "age" },
        { text: "Product", value: "product.name" },
        { text: "Weight (kg)", value: "weight" },
        { text: "Dose", value: "product.dose_rate_unit" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      noteHeaders: [
        { text: "Date", value: "date" },
        { text: "Age (Weeks)", value: "age" },
        { text: "Comments", value: "comments" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    dog() {
      return this.$store.getters["lhl/dogs/get"];
    },
  },
  methods: {
    ageWeek(dob, date) {
      var before = moment(dob, "YYYY-MM-DD HH:mm:ss");
      var now = moment(date, "YYYY-MM-DD HH:mm:ss");

      return now.diff(before, "weeks") + " weeks";
    },

    openDelete(model, item) {
      this.deleteBreed.model = model;
      this.deleteBreed.dialog = true;
      this.deleteBreed.item = item;
    },

    resetDelete() {
      this.deleteBreed.dialog = false;
      this.deleteBreed.model = {};
      this.deleteBreed.loading = false;
      this.deleteBreed.item = null;
    },

    saveDelete(item) {
      this.deleteBreed.loading = true;

      let playloadUrl = null;
      let args = [];

      if (item == "Medication") {
        playloadUrl = "lhl/dogs/deleteMedication";
        args = {
          appId: this.$route.params.id,
          dogId: this.$route.params.dogId,
          medicationId: this.deleteBreed.model.id,
        };
      } else if (item == "HealthNote") {
        playloadUrl = "lhl/dogs/deleteNote";
        args = {
          appId: this.$route.params.id,
          dogId: this.$route.params.dogId,
          noteId: this.deleteBreed.model.id,
        };
      } else if (item == "healthRecord") {
        playloadUrl = "lhl/dogs/deleteRecord";
        args = {
          appId: this.$route.params.id,
          dogId: this.$route.params.dogId,
          recordId: this.deleteBreed.model.id,
        };
      }

      this.$store
        .dispatch(playloadUrl, args)
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteBreed.loading = false;
        });
    },
  },
};
</script>
